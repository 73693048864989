import axios from '@/api/axios';
import { AppStateType } from '@/store/types';
import logger from '@/utils/logger';

export async function fetchTagungsAppConfig(eventName: string) {
  try {
    const response = await axios.get<AppStateType | AppStateType[]>('', {
      params:
        eventName !== ''
          ? {
              key: eventName,
            }
          : {},
    });
    return response.data;
  } catch (e) {
    logger.log(`Can't fetch the event of ${eventName}`, e);
    throw e;
  }
}
