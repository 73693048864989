import * as process from 'process';
import axios from 'axios';
// import { env } from '@/utils/env';

const url = process.env.REACT_APP_API_URL;
export default axios.create({
  baseURL: `${url}`,
  headers: {
    'Content-type': 'application/json, text/plain, */*',
  },
});
